<template>

  <h2 class="card-title mb-3 flex-grow-1">Voucher Belanja</h2>

  <p>Minimum saldo untuk topup kupon belanja adalah <span class="text-success">{{ toIdr(minimal_withdraw) }}</span>
    Saldo anda saat ini
    <span :class="{ 'text-danger': minimal_withdraw > saldo, 'text-success': minimal_withdraw <= saldo }">{{
        toIdr(saldo)
    }}</span>
  </p>

  <form @submit.prevent="submit">
    <div class="row g-4 mb-3">
      <div class="col-lg-6">
        <div class="form-check card-radio" @click="changeFraction()">
          <input id="shippingMethod01" v-model="voucher_purchase.fraction" v-bind:value="25000" type="radio"
            class="form-check-input" :checked="true">
          <label class="form-check-label" for="shippingMethod01">
            <span class="fs-20 float-end mt-2 text-wrap d-block fw-semibold">{{ toIdr(25000) }}</span>
            <span class="fs-14 mb-1 text-wrap d-block">Voucher Belanja</span>
            <!-- <span class="text-muted fw-normal text-wrap d-block">Berlaku 1 hari</span> -->
          </label>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-check card-radio" @click="changeFraction()">
          <input id="shippingMethod02" v-model="voucher_purchase.fraction" v-bind:value="50000" type="radio"
            class="form-check-input" :checked="false">
          <label class="form-check-label" for="shippingMethod02">
            <span class="fs-20 float-end mt-2 text-wrap d-block fw-semibold">{{ toIdr(50000) }}</span>
            <span class="fs-14 mb-1 text-wrap d-block">Voucher Belanja</span>
            <!-- <span class="text-muted fw-normal text-wrap d-block">Berlaku 1 hari</span> -->
          </label>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-check card-radio" @click="changeFraction()">
          <input id="shippingMethod03" v-model="voucher_purchase.fraction" v-bind:value="100000" type="radio"
            class="form-check-input" :checked="false">
          <label class="form-check-label" for="shippingMethod03">
            <span class="fs-20 float-end mt-2 text-wrap d-block fw-semibold">{{ toIdr(100000) }}</span>
            <span class="fs-14 mb-1 text-wrap d-block">Voucher Belanja</span>
            <!-- <span class="text-muted fw-normal text-wrap d-block">Berlaku 1 hari</span> -->
          </label>
        </div>
      </div>
    </div>
    <div class="float-end">
      <button :disabled="load || is_exceed" type="submit" class="btn btn-primary waves-effect waves-light">
        <i class="bx font-size-16 align-middle me-2"
          :class="{ 'bx-loader bx-spin': load, 'ri-shopping-bag-line': !load }"></i>
        Tukar Voucher Balanja {{ toIdr(voucher_purchase.fraction) }}
      </button>
    </div>
  </form>
</template>

<script>
import Swal from 'sweetalert2';
import { endpoint } from '../../host';
import errorService from '../../services/error-service';
import globalService from '../../services/global-service';
import httpService from '../../services/http-service';

export default {
  name: 'ShoppingVoucherTab',
  props: {
    callback: { type: Function, required: false },
  },
  data() {
    return {
      saldo: 0,
      is_exceed: false,
      minimal_withdraw: 25000,
      voucher_purchase: {
        fraction: 25000,
      },
      load: false,
    }
  },
  created() {
    this.getProfile();
  },

  methods: {
    async submit() {
      try {
        this.load = true;
        let res = await httpService.post(endpoint["voucher-purchase"], this.voucher_purchase);
        this.load = false;
        if (res.status == 200) {
          Swal.fire({
            title: "Berhasil",
            text: "tukar voucher belanja berhasil diajukan, silahkan tunggu verifikasi admin",
            icon: "success",
          });
          this.callback({ status: true });
        }
      } catch (error) {
        this.load = false;
        errorService.displayError(error);
      }
    },

    async getProfile() {
      let res = await httpService.get(endpoint.profile);
      let wallet = res.data.data.wallet;
      this.saldo = wallet.saldo;
      this.is_exceed = this.isValidWithDrawAmount();
    },

    changeFraction()
    {
      setTimeout(() => {
        this.is_exceed = this.isValidWithDrawAmount();
      }, 100);
    },

    isValidWithDrawAmount() {
      if (this.voucher_purchase.fraction <= this.saldo) {
        return false;
      }
      return true;
    },

    toIdr(a) {
      return globalService.toIdr(a);
    }
  }
}
</script>

<style>
.card-radio {
  color: #000;
}
</style>