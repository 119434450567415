<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
                        <h4 class="mb-sm-0">Kupon Belanja</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item active">Daftar Kupon Belanja</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <Saldo />
                </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <ShoppingVoucherTab :callback="withdrawCallback" />
                        </div>
                    </div>
                </div>

                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Riwayat Kupon Belanja</h4>
                        </div>

                        <div class="card-body">
                            <DataTable :url="dataTableUrl" :columns="columnHeaders" :actions="actions"
                                :reload="reloadDataTable" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { endpoint } from "../../host";
import DataTable from "../../components/datatable/DataTable.vue";
import moment from "moment";
import httpService from "../../services/http-service";
import errorService from "../../services/error-service";
import Swal from "sweetalert2";
import Saldo from "../includes/Saldo.vue";
import globalService from "../../services/global-service";
import ShoppingVoucherTab from "./ShoppingVoucherTab.vue";

export default {
    components: {
    DataTable,
    Saldo,
    ShoppingVoucherTab,
},

    created() {
        this.generateDataTableUrl();
        this.getProfile();
    },

    data() {
        return {
            load: false,
            saldo: 0,
            withdraw: {
                amount: null,
                bank_name: null,
                bank_account_name: null,
                bank_account_number: null,
            },
            show_filter: false,
            reloadDataTable: false,
            dataTableUrl: null,
            actions: [
                {
                    icon: "bx bx-show",
                    color: "btn-info",
                    tooltip: "detail",
                    onclick: (data) => {
                        this.$router.push({
                          name: "user.voucher-purchase-detail",
                          params: { id: data['transactionable']['id'] },
                        });
                    },
                },
            ],
            columnHeaders: [
                {
                    text: "Tanggal",
                    render: (data) => {
                        return moment(data.created_at).format("DD MMMM YYYY");
                    },
                },
                {
                    text: "Jenis", render: (data) => {
                        switch (data.transactionable_type) {
                            case "App\\Models\\Kupon Belanja":
                                return '<span class="badge bg-success"">withdraw</span>';
                            default:
                                return '<span class="badge bg-info"">kupon belanja</span>';
                        }
                    },
                },
                {
                    text: "Jumlah",
                    render: (data) => {
                        return globalService.toIdr(data["amount"]);
                    },
                },
                {
                    text: "Keterangan",
                    name: "description",
                },
                {
                    text: "Status",
                    render: (data) => {
                        switch (data.status) {
                            case "Y":
                                return '<span class="badge bg-success"">terverifikasi</span>';
                            case "N":
                                return '<span class="badge bg-danger"">tidak terverifikasi</span>'
                            default:
                                return '<span class="badge bg-warning"">Menunggu</span>';
                        }
                    },
                },
            ],
        };
    },

    methods: {
        generateDataTableUrl() {
            this.dataTableUrl = httpService.generateEndpointQuery(endpoint.transaction, {
                transactionable_type: "VoucherPurchase"
            })
        },

        withdrawCallback(data) {
            if (data.status == true) {
                this.reloadDataTable = !this.reloadDataTable;
            }
        },

        async getProfile() {
            let res = await httpService.get(endpoint.profile);
            let investor = res.data.data.investor;
            this.withdraw.bank_name = investor.bank_name;
            this.withdraw.bank_account_name = investor.bank_account_name;
            this.withdraw.bank_account_number = investor.bank_account_number;
        }
    },
};
</script>

<style>
</style>